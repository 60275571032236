import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const apiKey = process.env.REACT_APP_i18_API_KEY;
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;




i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ['localStorage','navigator','sessionStorage','querystring', 'cookie','htmlTag', 'path', 'subdomain'] },

    fallbackLng: "en",

    ns: ["default"],
    defaultNS: "default",

    supportedLngs: ["en","tr","fa","ar"],
    
    backend: {
      loadPath: loadPath
    }
  })

  export default i18next;
