import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";
import ScrollspyNav from "./scrollSpy";
// import i18next from 'i18next'
//Import Stickey Header
import StickyHeader from "react-sticky-header";
import "../../../node_modules/react-sticky-header/styles.css";
import logo from "./logo.png"
class Navbar_Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        // { id: 2, idnm: "features", navheading: "Features" },
        // { id: 3, idnm: "services", navheading: "Services" },
        // { id: 3, idnm: "about", navheading: "About" },
        // { id: 4, idnm: "pricing", navheading: "Pricing" },
        // { id: 5, idnm: "blog", navheading: "Blog" },
        { id: 6, idnm: "contact", navheading: "Contact" },
      ],
      isOpenMenu: false,
      lang: '',
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let TargetId = this.state.navItems.map((item) => {
      return item.idnm;
    });

    //  const languages = [
    //         { value: 'en', text: "English",dir: 'ltr', },
    //         { value: 'tr', text: "Türkçe",dir: 'ltr', },
    //         { value: 'fa', text: "فارسی", dir: 'rtl', },
    //         { value: 'ar', text: "اَلْعَرَبِيَّةُ", dir: 'rtl', }
    //     ]
    
  
        // This function put query that helps to 
        // change the language
        // const handleChange = e => { 
            
        //   this.setState({ lang: e.target.value });
        //     const loc = window.location.origin;
        //     const pathName = window.location.pathname;
        //     window.location.replace(loc +pathName+ "?lng=" + e.target.value) ;
       
        // }

    return (
      <React.Fragment>
        <StickyHeader
          header={
            <Navbar
              expand="lg"
              fixed="top"
              className={
                this.props.navClass + " navbar-custom sticky sticky-dark"
              }
            >
              <Container>
                <NavbarBrand className="logo" href="/">
                 <img src={logo} alt="logo" width={60}/> AFGPDI
                </NavbarBrand>

                <NavbarToggler className="p-0" onClick={this.toggle}>
                  <i className="mdi mdi-menu"></i>
                </NavbarToggler>

                <Collapse
                  id="navbarCollapse"
                  isOpen={this.state.isOpenMenu}
                  navbar
                >
                  <ScrollspyNav
                    scrollTargetIds={TargetId}
                    activeNavClass="active"
                    scrollDuration="300"
                    headerBackground="true"
                  >
                    <Nav className="navbar-nav navbar-center" id="mySidenav">
                      {this.state.navItems.map((item, key) => (
                        <NavItem
                          key={key}
                          className={item.navheading === "Home" ? "active" : ""}
                        >
                          <NavLink href={"#" + item.idnm}>
                            {" "}
                            {item.navheading}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </ScrollspyNav>
                  <div className="nav-button ms-auto">
                    <Nav className="navbar-right" navbar>
                      <NavItem>
                        {/* <Button
                          type="button"
                          className="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
                        >
                          Try it Free
                        </Button> */}
                        {/* <ul className="sub-menu listStyle"  value={this.lang} onChange={handleChange}  >
                                            {languages.map(item => {
                                                  return(  
                                                // eslint-disable-next-line
                                                  <li key={item.value}><a  href="#" 
                                                  onClick={() => {i18next.changeLanguage(item.value) }} 
                                                  key={item.value} value={item.value}>{item.text}</a></li>);
                                                })}
                                            </ul> */}
                      </NavItem>
                    </Nav>
                  </div>
                </Collapse>
              </Container>
            </Navbar>
          }
          stickyOffset={-100}
         
        ></StickyHeader>
      </React.Fragment>
    );
  }
}

export default Navbar_Page;
