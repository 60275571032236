import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

//Import Section Title
import SectionTitle from "../common/section-title";

class ContactUs extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section " id="contact">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Contact"
              // description="We thrive when coming up with innovative ideas but also understand that a smart concept should be supported with measurable results."
            />

            <Row>
              <Col lg="4">
                <div className="mt-4 pt-4">
                  <p className="mt-4">
                    <span className="h5">Address:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                    <a  target='_blank' rel="noopener noreferrer" href="https://goo.gl/maps/1tSfCR6C3xkDmkMd6" className='mailUs'>
                    Dereağzı, Aşık Veysel Sokak No:4, Beylikdüzü/İstanbul
                    </a>
                    </span>
                  </p>
                  {/* <p className="mt-4">
                    <span className="h5">Office Address 2:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                      2467 Swick Hill Street <br />
                      New Orleans, LA 70171
                    </span>
                  </p> */}
                  <p className="mt-4">
                    <span className="h5">Email:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                    <a className='mailUs' href="mailto:info@afgpdi.org">info@afgpdi.org</a>
                    </span>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Facebook:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                    <a className='mailUs' target="_blank" rel='noopener noreferrer' href="https://www.facebook.com/afgpdi">AFGPDI</a>
                    </span>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Twitter:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                    <a className='mailUs' target="_blank" rel='noopener noreferrer' href="https://twitter.com/afgpdi">AFGPDI</a>
                    </span>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Instagram:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                    <a className='mailUs' target="_blank" rel='noopener noreferrer' href="https://instagram.com/afg.pdi">AFGPDI</a>
                    </span>
                  </p>
                </div>
              </Col>
              <Col lg="8">
                <div className="custom-form mt-4 pt-4">
                  <div id="message"></div>
                  <AvForm name="contact-form" id="contact-form">
                  <Row>
                  <Col lg="12">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.5404896824593!2d28.602219314857432!3d40.96964422975112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b560959348d469%3A0x66fdd1617f655f76!2zRGVyZWHEn3rEsSwgQcWfxLFrIFZleXNlbCBTay4gTm86NCwgMzQ1MjggQmV5bGlrZMO8esO8L8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1676897313228!5m2!1str!2str"
                   width="600" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="AFGPDI"/>
                   </Col>
                   </Row>
                    {/* <Row>
                      <Col lg="6">
                        <AvField
                          type="text"
                          className="form-group mt-2"
                          name="name"
                          id="name"
                          placeholder="Your name*"
                          required
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your name",
                            },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <AvField
                          type="email"
                          className="form-group mt-2"
                          name="email"
                          id="email"
                          placeholder="Your email*"
                          required
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your email",
                            },
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <AvField
                          type="text"
                          className="form-group mt-2"
                          name="subject"
                          id="subject"
                          placeholder="Your Subject.."
                          required
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your topic",
                            },
                            pattern: {
                              value: "^[A-Za-z0-9]+$",
                              errorMessage: "topic is invalid!",
                            },
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <div className="form-group mt-2">
                          <textarea
                            name="comments"
                            id="comments"
                            rows="4"
                            className="form-control"
                            placeholder="Your message..."
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="text-end">
                        <Button className="submitBnt btn btn-primary">
                          Send Message
                        </Button>
                        <div id="simple-msg"></div>
                      </Col>
                    </Row> */}
                  </AvForm>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ContactUs;
